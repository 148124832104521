import { CountUp } from 'countup.js';

document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--stats');

	function create_block_stats() {

		return {
			block: null,
			config: null,
			blockObserver: null,
			statObserver: null,

			init: function (block) {
				this.block = block;
				this.config = { rootMargin: '0px 0px', threshold: 0 };
				this.blockObserver = new IntersectionObserver(this.blockIntersection.bind(this), this.config);
				this.statObserver = new IntersectionObserver(this.statIntersection.bind(this), this.config);

				this.blockObserver.observe(block);
			},

			statIntersection: function (entries) {
				const countHandler = (value) => {
					this.count(value);
				};

				entries.forEach(entry => {
					if (entry.intersectionRatio > 0) {
						this.statObserver.unobserve(entry.target);
						countHandler(entry.target);
					}
				});
			},

			blockIntersection: function (entries) {
				const animateHandler = () => {
					this.animate();
				};

				entries.forEach(entry => {
					if (entry.intersectionRatio > 0) {
						this.blockObserver.unobserve(entry.target);
						animateHandler(entry.target);
					}
				});
			},

			count: function (value) {
				const placesHandler = (num) => {
					this.places(num);
				};

				const num = value.dataset.value;
				const target = value.id;
				let dec = placesHandler(num);

				const options = {
					prefix: value.dataset.prefix,
					suffix: value.dataset.suffix,
					duration: 3,
					decimalPlaces: dec
				}
				const countUp = new CountUp(target, num, options);
				countUp.start();
			},

			places: (num) => {
				let text = num.toString();
				let index = text.indexOf(".");
				return index == -1 ? 0 : (text.length - index - 1);
			},

			animate: function (block) {

				let stats = this.block.querySelectorAll('.stat .value');

				stats.forEach(stat => {
					this.statObserver.observe(stat);
				});
			},

			log: (message) => {
				console.log(message);
			}
		}
	}

	blocks.forEach(block => {
		const block_stats = create_block_stats();
		block_stats.init(block);
	});
});